"use client"
import { redirect, usePathname } from 'next/navigation';
import { Metadata } from 'next';

export const metadata: Metadata = {
  title: 'Not Found',
};

export default function NotFound() {
  let path = usePathname()
  // Perform a 307 temporary redirect to the specified URL
  redirect('https://bn.barbequenation.com'+path);

  // You can include this as a fallback for environments where the redirect isn't handled
  return (
    <main>
      <section className='bg-white px-4'>
        <div className='layout flex min-h-screen flex-col items-center justify-center text-center text-black max-w-[650px] mx-auto'>
          <h2 className='mt-8 text-4xl md:text-6xl font-inter font-medium mb-4'>Redirecting...</h2>
          <p className='font-inter mb-5'>If you are not redirected automatically, <a href={`https://bn.barbequenation.com${path}`}>click here</a>.</p>
        </div>
      </section>
    </main>
  );
}
